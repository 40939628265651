.panel {
  position: fixed;
  margin: auto;
  z-index: 999;
  top: 30vh;
  left: 0;
  width: a;
  right: 0;
  padding: 25px;
  /* margin-top: calc(50vh-300px) ; */
  background-color: rgba(70, 70, 70, 0.774);
}
.form {
  display: grid;
  padding: 20px;
}
.form div {
  margin-left: auto;
  margin-right: auto;
}
.close {
  background-color: rgb(145, 145, 145);
  color: white;
  position: absolute;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 15px;
  border: none;
  border-radius: 0;
  margin: 2px;
  height: 35px;
  width: 35px;
}
.close:hover{
    background-color: rgb(82, 82, 82) ;
}
.form button {
  margin-top: 5px;
  margin-bottom: 5px;
  margin: 5px;
  background-color: rgb(187, 184, 184);
  color: white;
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  font-weight: 15px;
  border-radius: 0.6rem !important;
  font-size: 15px;
  border: none;
  border-radius: 0;

  padding: 15px 25px 15px 25px;
}
.form button:hover {
  background-color: rgb(161, 161, 161);
}
.form button:active {
  background-color: rgb(102, 101, 101);
}
.form input {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  display: block;
  width: 200px;
  height: 25px;
  font-family: inherit;
  appearance: none;
  background-clip: padding-box;
  line-height: 1;
  border-style: solid;
  border-width: rgb(238, 236, 236);
  border-color: rgb(238, 236, 236);
  color: rgb(48, 48, 48);
  background-color: rgb(238, 236, 236);
  border-radius: 0.6rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 15px;
}
