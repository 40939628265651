.header {
  display: grid;
  grid-template-columns: 60px 150px auto auto auto;
  background-color: rgba(255, 255, 255, 0.966);
  position: fixed;
  width: 100%;
  z-index: 101;
  margin: 0;
  top: 0;
}
@media screen and (max-width: 540px) {
  .header{
    grid-template-columns: 60px 90px auto auto auto;
  }
}
.headSearch {
  grid-column-start: 4;
  grid-column-end: 4;
  display: flex;
  padding: 10px;
}
.toMain{
    width: 100px;
    height: 60px;
    grid-column-start: 2;
    grid-column-end: 2;
    display: contents;
    text-align: center;
}
.toMain img{
    width: 100%;
    height: 50px;
    padding-left: 5px;
    padding-top: 5px;
  
}
.searchInput {
  outline: none;
  display: block;
  width: 100%;
  height: 40px;
  font-family: inherit;
  appearance: none;
  background-clip: padding-box;
  line-height: 1.0;
  border-style: solid;
  border-width:  rgb(238, 236, 236) ;
  border-color: rgb(238, 236, 236) ;
  color: rgb(48, 48, 48) ;
  background-color: rgb(238, 236, 236) ;
  border-radius: 0.6rem 0px 0px 0.6rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 15px;
}
.button{
    background-color: rgb(216, 212, 212) ;
    color: white;
    display: inline-flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    font-weight: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 15px;
    border: none;
    border-radius: 0;
    margin-left: 1px;
}
.button:hover{
    background-color: rgb(161, 161, 161) ;
}
.button:active{
    background-color: rgb(102, 101, 101) ;
}