.content {

    width: 65%;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 80px;
    background-color: rgb(248, 248, 248);
    position: fixed;
    padding-left: 15px;
    padding-right: 15px;
    
}
h2{
    color: rgb(77, 77, 77);
}
p{
    color: rgb(65, 65, 65);
}
@media screen and (max-width: 500px) {
    .content{
        width: 100%;
        overflow-y: scroll;
    }
}