
.container{
    width:100%;
    padding-top: 80px;
    display: inline-block;
    text-align: center;
  
   
}



.main{

    display: block;
}


