.drawer{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 80%;
    max-width: 240px;
    padding: 20px 10px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.966);
    z-index: 99;
    transform: translateX(0px);
    transition: transform .22s ease-in;
}
.drawer.close{
    transform: translateX(-240px);

}
.drawer ul {
    list-style: none;
    margin-top: 116px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    display: inline-block;
   

}
.drawer ul li {
    margin-bottom: 15px;

}

.drawer ul li a {
    
    color: black;
    font-size: 20px;
    text-decoration: none;
    position: relative;
    padding: 0 20px 10px 20px;
    transition: opacity 3.s;
    cursor: default;
    

   
}
.drawer ul li a svg {
    
    font-size: 30px;
    padding: 2px;
    vertical-align: middle;
    margin: 5px;
    color: rgb(150, 200, 199);
}
.drawer ul li a:hover  {
    opacity: .7;
}


