.filterContainer{
    position: fixed;
    
    left: 0;
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    right: 0;
    width: 60%;
    z-index: 999;
    background-color: rgba(240, 239, 239, 0.945) ;
    /* height: 500px; */
    /* margin: auto; */
    text-align: center;

}
@media screen and (max-width: 800px) {
    .filterContainer{
        width: 95%;
        overflow-y: scroll;
    }
 
}
.close
{
    background-color: rgb(145, 145, 145) ;
    color: white;
    display: inline-flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    font-weight: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 15px;
    border: none;
    border-radius: 0;
    margin: 2px;
    height: 35px;
    width: 35px;
    
   
}
.gener {
    padding: 10px;
    left: 0;
    right: 0;
    
}
.list {

    display: grid;
}
.gener button{
    background-color: rgb(145, 145, 145) ;
    color: white;
    display: inline-flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    font-weight: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 15px;
    border: none;
    border-radius: 0;
    margin: 2px;
    height: 25px;
}
.gener button:hover{
    background-color: rgb(82, 82, 82) ;
}
.gener button:active{
    background-color: rgb(102, 101, 101) ;
}
.close:hover{
    background-color: rgb(82, 82, 82) ;
}
.close:active{
    background-color: rgb(102, 101, 101) ;
}