.itemFilm:hover .nameFilm {
    opacity: 100;
}
.itemFilm:hover .ratingFilm {
    opacity: 100;
}
.itemFilm:hover .infoFilm {
    opacity: 100;
}
.itemFilm:hover .watch {
    opacity: 100;
}
.itemFilm img{
    overflow: hidden; 
    width: 200px;
    height: 300px;
    
}
.itemFilm img[alt] {
    background-color: rgba(58, 58, 58, 0.124);
    width: 200px;
    height: 300px;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    top: 0;
    bottom: 0;
 }
.watch{
    opacity: 0;
    position: absolute;
    margin: 0 0 4px 0;
    z-index: 2;
    width: calc(100% - 6px) ;
    background-color: rgba(0, 0, 0, 0.63);
    border: 1px solid rgb(0, 0, 0);
    display: block;
    bottom: 0;
    padding-bottom: 20px;
  
    
}
.infoFilm{
    opacity: 0;
    position: absolute;
    margin: 0 0 4px 0;
    z-index: 2;
    width: calc(100% - 11px) ;
    background-color: rgba(0, 0, 0, 0.63);
    border: 1px solid rgb(0, 0, 0);
    display: block;
    color: white;
    top: 0;
    font-size: 12px;
    padding: 1px;
    margin-top: 50%;
    cursor: default;
    word-break: break-word;
    
}
.ratingFilm{
    opacity: 0;
    position: absolute;
    margin: 0 0 4px 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.63);
    border: 1px solid rgb(0, 0, 0);
    display: block;
    color: white;
    top: 0;
    right: 0;
    font-size: 12px;
    margin-right: 4px;
    margin-top: 90%;
    padding: 5px;
    cursor: default;
}
.itemFilm {
    margin: 0 0 4px 0;
    display: inline-block;
    padding: 0 2px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.075);
    
}
.watch svg {
    font-size: 35px !important;
    margin-top: 15px
}
.watch a {
    text-decoration: none;
    color: white;
}
.nameFilm{
    
    opacity: 0;
    position: absolute;
    margin: 0 0 4px 0;
  
    z-index: 2;
    width: calc(100% - 6px) ;
    background-color: rgba(0, 0, 0, 0.63);
    border: 1px solid rgb(0, 0, 0);
    display: block;
    font-size: 18px;
    top: 0;
    padding-bottom: 20px;

    
}
.nameFilm a {
    text-decoration: none;
    color: white;
}