.ctBackground {
    background-color: #f8f8f8;
    min-height: 100vh
}
.flexcolumn{
    display: flex;
    flex-direction: column;
    padding: 50px;
    margin-top: 50px;
}
.flexrow{
    display: flex;
    flex-direction: row;
}
.flexbeetween{
    justify-content: space-between;
}
.pButton{
    padding: 10px 15px 10px 15px;
    background-color: rgb(161, 161, 161) ;
    display: inline-block;
    margin: 10px 10px 0 0;
    border: none;
    color: white;
    font-size: 16px;
  }
.marginTop{
    margin-top: 50px;
}
@media (max-width: 975px) { 
    .description{
        height: auto;
    }
    .flexPanelInfo{
        display: flex !important;
        flex-wrap: wrap !important;
       
    }
    .flexcolumn{
        padding: 50px 10px;
     
    }
}
.flexPanelInfo{
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: auto auto;
}
.videoPanel{
    margin-top: 50px;
}
.title_panel{
    display: grid;
    grid-template-columns: auto 50px;
  }

  .flexbeetween button{
    padding: 15px;
    background-color: rgb(216, 212, 212) ;
    color: white;
    display: inline-flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    font-weight: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 15px;
    border: none;
    border-radius: 0;
    margin-left: 1px;
    height: 40px;
  }
  .poster {
    float: left;
    position: relative;
    width: 200px;
    margin: 0 20px 15px 0;
  }
